<template>
  <div>
    <div style="font-size: 20px">数据概览</div>
    <el-divider class="margin10"></el-divider>
    <div class="previewTopList">
      <div class="previewLi">
        <div class="top">
          未提交
          <el-tooltip
            effect="dark"
            content="未提交教学计划数量"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.waitSubmitCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          待审核
          <el-tooltip
            effect="dark"
            content="已提交教学计划但未审批"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.waitApproveCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          总课时量
          <el-tooltip
            effect="dark"
            content="所有学校提交的教学计划中课时数量总和，一节课为一个课时"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">{{ planOverview.lessonCount }}</div>
      </div>
      <div class="previewLi">
        <div class="top">
          生均课时量
          <el-tooltip
            effect="dark"
            content="结合班级总数计算平均每个班级所需学习的课时量"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">
          {{ planOverview.studentAverageLessonCount }}
        </div>
      </div>
      <div class="previewLi">
        <div class="top">
          师均课时量
          <el-tooltip
            effect="dark"
            content="结合教师总数计算人均所需授课课时量"
            placement="top-start"
          >
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="bottom">
          {{ planOverview.teacherAverageLessonCount }}
        </div>
      </div>
    </div>
    <div style="font-size: 20px">教学计划提交明细</div>
    <el-divider class="margin10"></el-divider>
    <!-- 发起手机任务 -->
    <!-- <el-row style="margin-bottom: 20px"></el-row> -->
    <!-- 条件 -->
    <el-row>
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="学校名称" class="formItemBoxStyle">
          <el-input
            @change="changeConditionForm"
            v-model="conditionForm.sysOrgSchoolName"
          ></el-input>
        </el-form-item>
        <el-form-item label="年级" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.sysOrgSchoolGradeId"
          >
            <!-- <el-option label="区域一" value="shanghai"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.submitStatus"
          >
            <el-option label="未提交" :value="1"></el-option>
            <el-option label="已提交" :value="2"></el-option>
            <el-option label="驳回提交" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批结果" class="formItemBoxStyle">
          <el-select
            @change="changeConditionForm"
            v-model="conditionForm.approveStatus"
          >
            <el-option label="未审批" :value="1"></el-option>
            <el-option label="审批通过" :value="2"></el-option>
            <el-option label="审批不通过" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="inquireBtn">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="batchBtn">批量审批</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格 -->
    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="selectable" width="55">
        </el-table-column>
        <el-table-column
          prop="sysOrgSchoolName"
          label="学校名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="sysOrgSchoolGradeName"
          label="年级"
        ></el-table-column>
        <el-table-column prop="lessonCount" label="总课时"></el-table-column>
        <el-table-column
          prop="teacherAverageLessonCount"
          width="100"
          label="师均课时"
        ></el-table-column>
        <el-table-column
          prop="studentAverageLessonCount"
          width="100"
          label="生均课时"
        ></el-table-column>
        <el-table-column prop="standards" width="100" label="是否达标">
          <template slot-scope="scope">
            {{ scope.row.standards ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="submitStatus" label="提交状态">
          <template slot-scope="scope">
            <span v-if="scope.row.submitStatus == 1">未提交</span>
            <span v-else-if="scope.row.submitStatus == 2">已提交</span>
            <span v-else-if="scope.row.submitStatus == 3">驳回提交</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="submitTime" label="提交时间">
          <template slot-scope="scope">
            {{ scope.row.submitTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="approverName" label="审批人"></el-table-column>
        <el-table-column label="审批结果">
          <template slot-scope="scope">
            <span v-if="scope.row.approveStatus == 1">未审批</span>
            <span v-else-if="scope.row.approveStatus == 2">审批通过</span>
            <span v-else-if="scope.row.approveStatus == 3">审批不通过</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="approveMark"
          label="审批建议"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailBtn(scope.row)" type="text"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
      <el-row style="display: flex; justify-content: center">
        <el-button
          @click="
            () => {
              this.$router.back()
            }
          "
          >返回</el-button
        >
      </el-row>
    </el-row>
    <!-- 审批弹框 -->
    <el-dialog title="审批" :visible.sync="dialogVisibleBatch" width="30%">
      <span style="margin-bottom: 10px; display: block">审批建议：</span>
      <el-input
        placeholder="请输入审批结果"
        v-model="batchText"
        type="textarea"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitExpPlanFunc(3)">全部不通过</el-button>
        <el-button type="primary" @click="submitExpPlanFunc(2)"
          >全部通过</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      title="教学计划详情"
      :visible.sync="dialogVisibleDetail"
      width="1600px"
    >
      <el-tabs v-model="activeDetailName" @tab-click="handleDetailClick">
        <el-tab-pane label="教学计划安排概览" name="courseStandard">
          <!-- :span-method="objectSpanMethod" -->
          <el-table
            border
            ref="courseStandard"
            :data="courseStandard"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            style="width: 100%"
          >
            <el-table-column
              prop="experimentName"
              label="实验名称"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column prop="sysSubjectDic.sysDicName" label="所属科目">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.crsOpenPlanCourseStandardList"
                  :key="item.crsExpCourseId"
                >
                  <el-divider v-if="i != 0"></el-divider>
                  {{ item.sysSubjectDic?.sysDicName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="schoolSectionName"
              label="适用学段"
            ></el-table-column>
            <el-table-column
              prop="sysSchoolClassGradeName"
              label="适用年级"
            ></el-table-column>
            <el-table-column prop="demoExperimentStatus" label="演示实验">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.crsOpenPlanCourseStandardList"
                  :key="item.crsExpCourseId"
                >
                  <el-divider v-if="i != 0"></el-divider>
                  <span v-if="item.demoExperimentStatus == 1">必做</span>
                  <span v-else-if="item.demoExperimentStatus == 2">选做</span>
                  <span v-else>未知</span>
                </div>
                <!-- <span v-if="scope.row.demoExperimentStatus == 1">必做</span>
                <span v-else-if="scope.row.demoExperimentStatus == 2"
                  >选做</span
                >
                <span v-else>未知</span> -->
              </template>
            </el-table-column>
            <el-table-column prop="groupExperimentStatus" label="分组实验">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.crsOpenPlanCourseStandardList"
                  :key="item.crsExpCourseId"
                >
                  <el-divider v-if="i != 0"></el-divider>
                  <span v-if="item.groupExperimentStatus == 1">必做</span>
                  <span v-else-if="item.groupExperimentStatus == 2">选做</span>
                  <span v-else>未知</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="arrClassNum" label="本校排课情况">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.crsOpenPlanCourseStandardList"
                  :key="item.crsExpCourseId"
                >
                  <el-divider v-if="i != 0"></el-divider>
                  {{ item.arrClassNum }}个班级/{{ item.totalClassNum }}个班级
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="standards" label="是否按要求排课">
              <template slot-scope="scope">
                <div
                  v-for="(item, i) in scope.row.crsOpenPlanCourseStandardList"
                  :key="item.crsExpCourseId"
                >
                  <el-divider v-if="i != 0"></el-divider>
                  {{ item.standards ? '是' : '否' }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="实验课程明细" name="curriculumList">
          <div class="previewTopList">
            <div class="previewLi">
              <div class="top">
                总课时量
                <el-tooltip
                  effect="dark"
                  content="统计所有课时量"
                  placement="top-start"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div class="bottom">{{ dialogPlanOverview.lessonCount }}</div>
            </div>
            <div class="previewLi">
              <div class="top">
                生均课时量
                <el-tooltip
                  effect="dark"
                  content="结合班级总数计算平均每个班级所需学习的课时量"
                  placement="top-start"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div class="bottom">
                {{ dialogPlanOverview.studentAverageLessonCount }}
              </div>
            </div>
            <div class="previewLi">
              <div class="top">
                师均课时量
                <el-tooltip
                  effect="dark"
                  content="结合教师总数计算人均所需授课课时量"
                  placement="top-start"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
              <div class="bottom">
                {{ dialogPlanOverview.teacherAverageLessonCount }}
              </div>
            </div>
          </div>
          <el-row style="margin-top: 20px">
            <el-table
              v-loading="loading"
              border
              ref="dialogTableData"
              :data="dialogTableData"
              tooltip-effect="dark"
              :header-cell-style="{
                'text-align': 'center',
                backgroundColor: '#dfe6ec',
              }"
              :cell-style="{ 'text-align': 'center' }"
              style="width: 100%"
              height="500px"
            >
              <el-table-column
                label="序号"
                type="index"
                width="50px"
              ></el-table-column>
              <el-table-column
                prop="sysSubjectDicId"
                label="所属科目"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.sysSubjectDic?.sysDicName }}
                </template>
              </el-table-column>
              <el-table-column prop="crsExpCourseName" label="课程名称">
                <template slot-scope="scope">
                  <el-link type="primary">{{
                    scope.row.crsExpCourseName
                  }}</el-link>
                </template>
              </el-table-column>
              <el-table-column
                prop="sysOrgSchoolRoomName"
                label="演示实验"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.crsOpenPlanCourseDTO?.demoExpStatus == 1
                      ? '必做'
                      : scope.row.crsOpenPlanCourseDTO?.demoExpStatus == 2
                      ? '选做'
                      : ''
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="sysOrgSchoolRoomName"
                label="分组实验"
                width="120"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.crsOpenPlanCourseDTO?.groupExpStatus == 1
                      ? '必做'
                      : scope.row.crsOpenPlanCourseDTO?.groupExpStatus == 2
                      ? '选做'
                      : ''
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="sysOrgSchoolClassName"
                label="上课班级"
              ></el-table-column>
              <el-table-column
                prop="sysOrgSchoolClassStudentCount"
                label="班级人数"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="sysOrgSchoolRoomName"
                label="上课地点"
              ></el-table-column>
              <el-table-column prop="schoolTime" label="上课日期" width="120">
                <template slot-scope="scope">
                  {{ scope.row.schoolTime | dayFilters }}
                </template>
              </el-table-column>
              <el-table-column prop="sysSchoolClassSession" label="节次">
                <template slot-scope="scope">
                  {{
                    scope.row.sysSchoolClassSession?.sysSchoolClassSessionName
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="teacherName"
                label="任课老师"
                width="100"
              ></el-table-column>
            </el-table>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-pagination
              @size-change="dialogPageHandleSizeChange"
              @current-change="dialogPageHandleCurrentChange"
              :current-page="dialogPage.pageIndex"
              :page-sizes="[10, 20, 50, 100, 150, 200]"
              :page-size="dialogPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="dialogPage.totalSize"
            ></el-pagination>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="实验课课程表" name="curriculumData">
          <curriculumCom ref="curriculumCom" />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import curriculumCom from './courseArrangement/curriculum.vue'
import {
  getPlanOverview,
  getPlanDetail,
  submitExpPlan,
  getCourseStandardPlan,
} from '@/api/courseStart/lessonPlansManage.js'
import {
  getCourseOverview,
  getCoursePlanList,
  getClassSchedule,
} from '@/api/courseStart/experimentCurriculum.js'
export default {
  name: 'lessonPlansManageCompletion',
  components: {
    curriculumCom,
  },
  data() {
    return {
      crsExpPlanId: '',
      planOverview: {
        lessonCount: 0,
        studentAverageLessonCount: 0,
        teacherAverageLessonCount: 0,
        waitApproveCount: 0,
        waitSubmitCount: 0,
      },
      conditionForm: {
        approveStatus: '',
        // schoolSectionDicId: '',
        submitStatus: '',
        sysOrgSchoolGradeId: '',
        sysOrgSchoolName: '',
      },
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      selectionList: [],
      // 审批文本
      batchText: '',
      dialogVisibleBatch: false,
      // 查看详情弹框
      dialogVisibleDetail: false,
      activeDetailName: 'courseStandard',
      dialogPlanOverview: {},
      dialogTableData: [],
      dialogTableCondition: {},
      //分页
      dialogPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      courseStandard: [],
      curriculumData: {},
      timestamp: '',
    }
  },
  async mounted() {
    this.crsExpPlanId = Number(this.$route.query.crsExpPlanId)
    this.timestamp = new Date().valueOf()
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    this.getPlanOverviewFunc(this.crsExpPlanId)
    this.getPlanDetailFunc({})
  },
  methods: {
    // 实验教学计划概览
    getPlanOverviewFunc(data) {
      getPlanOverview(data)
        .then((res) => {
          if (res.success) {
            this.planOverview = res.data
          }
        })
        .catch((err) => {})
    },
    // 表格
    getPlanDetailFunc(data) {
      getPlanDetail(
        this.crsExpPlanId,
        this.page.pageIndex,
        this.page.pageSize,
        data
      )
        .then((res) => {
          if (res.success) {
            this.selectionList = []
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    // 条件
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getPlanDetailFunc(conditionObj)
    },
    // 重置
    resetBtn() {
      for (let key in this.conditionForm) {
        this.conditionForm[key] = ''
      }
      this.page.pageIndex = 1
      this.getPlanDetailFunc({})
    },
    // 查询
    inquireBtn() {
      this.dialogPage.pageIndex = 1
      this.changeConditionForm()
    },
    // 选择
    handleSelectionChange(val) {
      this.selectionList = val
    },
    selectable(row, i) {
      return row.approveStatus == 1
    },
    batchBtn() {
      if (!this.selectionList.length) {
        return this.$message.info('请先勾选')
      }
      this.batchText = ''
      this.dialogVisibleBatch = true
    },
    // 批量审批
    async submitExpPlanFunc(key) {
      let arr = this.selectionList.map((e) => {
        let data = {
          approveStatus: key,
          crsOpenPlanId: e.crsOpenPlanId,
          mark: this.batchText,
        }
        return data
      })
      await submitExpPlan(this.crsExpPlanId, arr)
        .then((res) => {
          if (res.success) {
            this.dialogVisibleBatch = false
            this.inquireBtn()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 查看详情按钮
    async detailBtn(row) {
      await this.getCourseStandardPlanFunc(row.crsOpenPlanId)
      let data = {
        crsOpenPlanId: row.crsOpenPlanId,
        // schoolSectionDicId: row.schoolSectionDicId,
        // sysOrgSchoolId: row.sysOrgSchoolId,
      }
      await this.getCourseOverviewFunc(data)
      this.dialogTableCondition = {
        crsOpenPlanId: row.crsOpenPlanId,
        schoolSectionDicId: row.schoolSectionDicId,
        sysOrgSchoolClassName: row.sysOrgSchoolClassName,
        // sysOrgSchoolId: row.crsOpenPlanId,
        sysOrgSchoolId: row.sysOrgSchoolId,
        sysSubjectDicId: row.sysSubjectDicId,
      }
      await this.getCoursePlanListFunc(this.dialogTableCondition)
      this.activeDetailName = 'courseStandard'
      this.dialogVisibleDetail = true
      this.$nextTick(() => {
        this.$refs.curriculumCom.showClose = false
        this.getClassScheduleFunc({
          crsOpenPlanId: row.crsOpenPlanId,
          schoolSectionDicId: row.schoolSectionDicId,
        })
      })
    },

    handleDetailClick(tab, event) {
      // console.log(tab, event)
    },

    // 教学计划安排概览
    async getCourseStandardPlanFunc(crsOpenPlanId) {
      await getCourseStandardPlan(crsOpenPlanId)
        .then((res) => {
          if (res.success) {
            this.courseStandard = res.data
            // this.courseStandard.forEach((i) => {
            //   if (i.crsOpenPlanCourseStandardList) {
            //     i.crsOpenPlanCourseStandardList.forEach((j) => {
            //       j.parentId = i.crsExpCourseId
            //     })
            //   }
            //   if (i.crsOpenPlanCourseStandardList) {
            //     this.courseStandard.push(...i.crsOpenPlanCourseStandardList)
            //   } else {
            //     this.courseStandard.push(i)
            //   }
            // })
            console.log('this.courseStandard', this.courseStandard)
          }
        })
        .catch((err) => {})
    },
    // 弹窗开课概览
    async getCourseOverviewFunc(data) {
      await getCourseOverview(data)
        .then((res) => {
          if (res.success) {
            this.dialogPlanOverview = res.data
          }
        })
        .catch((err) => {})
    },
    // 分页查询课程节次
    getCoursePlanListFunc(data) {
      getCoursePlanList(
        this.dialogPage.pageIndex,
        this.dialogPage.pageSize,
        data
      )
        .then((res) => {
          if (res.success) {
            this.dialogTableData = res.data
            this.dialogPage.totalSize = res.total
          } else {
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    dialogPageHandleCurrentChange(val) {
      this.dialogPage.pageIndex = val
      this.getCoursePlanListFunc(this.dialogTableCondition)
    },
    //pageSize 改变时会触发
    dialogPageHandleSizeChange(val) {
      this.dialogPage.pageIndex = 1
      this.dialogPage.pageSize = val
      this.getCoursePlanListFunc(this.dialogTableCondition)
    },
    // 课程表
    async getClassScheduleFunc(data) {
      // await getClassSchedule(this.timestamp,data)
      //   .then((res) => {
      //     if (res.success) {
      //       this.curriculumData = res.data
      //     }
      //   })
      //   .catch((err) => {})
      this.$refs.curriculumCom.getClassScheduleFunc(data)
    },
    subjectTypeFilters(key) {
      return this.$store.state.public_data.sysSubjectDicList.find(
        (e) => e.value === key
      )?.name
    },
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 0 && columnIndex === 2) {
    //     const parent = this.courseStandard.find(i => i.crsExpCourseId === row.parentId)
    //     if (parent && row.parentId === parent.crsOpenPlanCourseStandardList[0].parentId) {
    //       return {
    //         rowspan: parent.crsOpenPlanCourseStandardList.length,
    //         colspan: 1
    //       }
    //     } else if (parent && row.parentId !== parent.crsOpenPlanCourseStandardList[0].parentId) {
    //       return {
    //         rowspan: 0,
    //         colspan: 0
    //       }
    //     }
    //   }
    // },
  },
  watch: {},
  filters: {
    // subjectTypeFilters(key) {
    //   this.$store.state.public_data.sysSubjectDicList.find(
    //     (e) => e.value === key
    //   )?.name
    // },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin: 10px 0 20px 0;
}
.previewTopList {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
}
.previewLi {
  margin-right: 50px;
  .top {
    color: #929292;
    font-size: 16px;
    i {
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 30px;
    span {
      font-size: 16px;
    }
  }
}

.el-divider--horizontal {
  margin: 10px 0;
}
</style>
